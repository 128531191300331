<template>
  <div class="detail" v-if="newsData">
    <div class="adbanner" v-if="newsData.webImg">
      <img :src="newsData.webImg" />
    </div>
    <h2 v-if="newsData.title">
          <span class="line"></span>{{newsData.title}}<span class="line"></span>
    </h2>
    <div class="detail-box">
        
        <div class="detail-content container">
          <div class="content-box">
            <div class="return-box">
              <router-link :to="{name: 'Detail-List'}" class="return"></router-link>
            </div>  
            <div class="ad" v-if="newsData.webImg1">
                <img :src="newsData.webImg1" />
            </div> 
            <h3>{{newsData.subTitle}}</h3> 
            <div class="content"v-html="newsData.content"></div>
          </div>
          
        </div>
        
       
    </div>
  </div>
</template>

<script>
import Utils from '@/api/Utils.js' 
export default {
  name: 'Detail',
  components: {
    
  },
  data(){
    return {
        newsData: null
    }
  },
  watch: {
    $route: {
      handler: function(val, oldVal){
        this.getdata();
      },
      deep: true
    }
  },
  methods:{
    getdata(){
      if(!this.$route.params.id){
        return ;
      }
      Utils.post(`v1/outer/article/newInfo`, {id: this.$route.params.id}).then((res)=>{
        if(res && res.code == 200){
          this.newsData = res.data
        }
      })
    }
  },
  mounted(){
    this.getdata();
    
  }
}
</script>
<style scoped lang="stylus">
.detail{
  background : #f7f8f8;
  padding-bottom: 120px;
}
.ad{
   width 100%
   overflow: hidden;
   img{
      display block 
      width 100%
   }
}
.adbanner{
   height: 538px;
   overflow: hidden;
   position relative
   img{
      display block 
      height: 538px;
      margin: 0 auto;
      position absolute
      left: 50%
      transform translate3d(-50%,0,0)
      top: 0
   }
}
h2{
  text-align center;
  font-size: 39px
  padding-top 95px
  span{
    display inline-block
    width 44px
    height 4px
    margin: 0 20px
    vertical-align: middle;
    background: url('./images/line.png') 50% 0 no-repeat;
  }
}
h3{
  color : #3e3a39;
  font-size: 25px;
  line-height 2
  margin: 65px 0 43px;
  padding: 0 20px
}
.detail-box{
  width 1020px
  background #fff;
  margin: 166px auto 0;
  padding-top 100px
  position relative
  background: url('./images/bg.png') 50% 0 no-repeat;
}
.detail-content{
  width 841px
  background #fff;
  margin: 0 auto;
  box-sizing : border-box;
  padding: 0 20px
  
}
.content-box{
  background #fff
  padding: 20px 20px 140px 0
}
.content{
  padding: 0 20px
  font-size: 12px;
  line-height 22px;
  color : #3e3a39;
}
.return-box{
   text-align right;
}
.return{
  display inline-block;
  width 91px;
  height 28px;
  background url('./images/return.png') no-repeat;
  background-size 100% auto
  position absolute
  right: 120px
  top: 70px
  
}

</style>
